:root {
    --primary-color: mediumslateblue;
}

.hyped-box-container * {
    box-sizing: border-box;
}

.hyped-box-container .card {
    width: 70%;
    position: relative;
    background-color: rgb(16 16 16);
    border: 1px solid rgb(255 255 255 / 5%);
    border-radius: 1.5rem;
    padding: 1rem;
    margin: 0 auto;
}

.hyped-box-container .card:after {
    content: "";
    height: 70px;
    width: 15px;
    position: absolute;
    left: -1px;
    top: 65%;
    transition: top, opacity;
    transition-duration: 600ms;
    transition-timing-function: ease;
    background: linear-gradient(
            transparent,
            var(--primary-color),
            transparent
    );
    opacity: 0;
}

.hyped-box-container .card:after {
    top: 65%;
    opacity: 0;
}

.hyped-box-container .card:hover:after {
    top: 25%;
    opacity: 1;
}

.hyped-box-container .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-image: radial-gradient(
            rgba(255, 255, 255, 0.1) 1px,
            transparent 1px
    );
    background-position: 50% 50%;
    background-size: 1.1rem 1.1rem;
    padding: 4rem;
    border-radius: 1.25rem;
    overflow: hidden;
}

.hyped-box-container .card-content > :is(h1, h3, p) {
    text-align: center;
}

.hyped-box-container .card-content > h1 {
    color: rgb(250 249 246);
    font-size: 2.6rem;
}

.hyped-box-container .card-content > h3 {
    color: var(--primary-color);
    text-transform: uppercase;
    font-size: 0.76rem;
}

.hyped-box-container .card-content > p {
    color: rgb(255 255 255 / 75%);
    line-height: 1.5rem;
}

.App.light .hyped-box-container .card {
    background-color: rgb(128 117 174 / 29%);
    border: 1px solid rgb(90 50 144 / 53%);
}

.App.light .hyped-box-container .card-content {
    background-image: radial-gradient(#ffffff42 1px, transparent 1px);
}

.App.light .hyped-box-container .card-content > h1 {
    color: rgb(15, 27, 35);
}

.App.light .hyped-box-container .card-content > h3 {
    color: #130c3b;
}

.App.light .hyped-box-container .card-content > p {
    color: #041017;
}

@media(max-width: 992px) {
    .hyped-box-container .card {
        width: 100%;
        margin: 0;
        padding: 0.75rem;
        border-radius: 1rem;
    }

    .hyped-box-container .card-content {
        padding: 1rem;
    }

    .hyped-box-container .card-content > h1 {
        font-size: 2.2rem;
    }

    .hyped-box-container .card-content > p {
        text-align: justify;
    }
}
