.feature-label {
    text-align: center;
    background: #272727;
    color: white;
    padding: 15px;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .feature-image {
        position: relative !important;
        opacity: 1 !important;
        transform: none !important;
        top: auto !important;
        left: auto !important;
        right: auto !important;
        margin-bottom: 20px; /* Adjust as needed */
    }
}
