.guide-box {
    height: 200px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    transition: background-size 2s ease-in;
    -moz-transition: background-size 2s ease-in;
    -ms-transition: background-size 2s ease-in;
    -o-transition: background-size 2s ease-in;
    -webkit-transition: background-size 2s ease-in;
    cursor: pointer;
}

.guide-box:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(0deg, #000000, transparent);
    z-index: 0;
}

.guide-box:hover {
    background-size: 110%;
}

.avatar-container {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 1;
}

.user-avatar {
    width: 32px;
    height: 32px;
}

.city-name {
    position: absolute;
    bottom: 0;
    left: 10px;
    color: white;
    z-index: 1;
}
