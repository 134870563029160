.animated-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Ensure it doesn't interfere with other elements */
    z-index: -1; /* Place it behind other content */
    transition: background 0.2s ease; /* Adjust the transition duration as needed */
}

.animated-background.light {
    background-color: lightgray;
}

.animated-background.dark {
    background-color: #0a1929;
}
