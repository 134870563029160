@media (max-width: 992px) {
    .create-guide-container {
        width: 200%;
        display: flex;
        flex-direction: row;
        position: relative;
        top: 0;
        left: 0;
        transition: left 0.2s ease-in;
        margin-bottom: 40px;
    }
    .create-guide-container.map {
        left: -100%;
    }
    .create-guide-container .main-content,
    .create-guide-container .sidebar {
        width: 50%;
    }
}
