:root {
    --purple: rgb(123, 31, 162);
    --violet: rgb(103, 58, 183);
    --pink: rgb(244, 143, 177);
}

.hero-message {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
    justify-content: center;
}

.hero-images {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.parallax-image {
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in-out, top 0.5s ease-in-out, right 0.5s ease-in-out;
    border-radius: 15px;
}

.parallax-image:nth-child(1) {
    z-index: 2;
    width: 300px;
    height: 600px;
    transform: rotate3d(1, 1, 0, 35deg) translateX(80px) translateY(-30px);
    background-size: contain;
}

.parallax-image:nth-child(2) {
    z-index: 1;
    width: 450px;
    height: 600px;
    transform: rotate3d(1, 1, 0, 35deg) translateX(20px) translateY(20px);

}

.parallax-image:nth-child(3) {
    width: 190px;
    height: 170px;
    position: absolute;
    z-index: 3;
    transform: rotate3d(1, 1, 0, 35deg) translateX(20px) translateY(20px);
    top: 90px;
    right: 75px;
}

.hero-images:hover .parallax-image:nth-child(1) {
    transform: none;
    border-radius: 15px 0 0 15px;
}
.hero-images:hover .parallax-image:nth-child(2){
    transform: none;
    border-radius: 0 15px 15px 0;
}
.hero-images:hover .parallax-image:nth-child(3){
    top: 70px;
    right: 58px;
    transform: none;
    border-radius: 0;
}

@keyframes background-pan {
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
}

@keyframes scale {
    from, to {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}

.hero-message .magic {
    display: inline-block;
    position: relative;
}

.hero-message .magic > .magic-star {
    --size: clamp(20px, 1.5vw, 30px);

    animation: scale 700ms ease forwards;
    display: block;
    height: var(--size);
    left: var(--star-left);
    position: absolute;
    top: var(--star-top);
    width: var(--size);
}

.hero-message .magic > .magic-star > svg {
    animation: rotate 1000ms linear infinite;
    display: block;
    opacity: 0.7;
}

.hero-message .magic > .magic-star > svg > path {
    fill: var(--violet);
}

.hero-message .magic > .magic-text {
    animation: background-pan 3s linear infinite;
    background: linear-gradient(
            to right,
            var(--purple),
            var(--violet),
            var(--pink),
            var(--purple)
    );
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
    margin: 0 10px;
    font-size: 45px;
}

@media (max-width: 992px) {
    .hero-message h2 {
        font-size: 32px;
    }
    .hero-message .magic > .magic-text {
        font-size: 32px;
        margin: 0 0 0 10px;
    }
    .hero-images .parallax-image:nth-child(1) {
        transform: none;
        border-radius: 15px 0 0 15px;
        height: 290px;
    }
    .hero-images .parallax-image:nth-child(2){
        transform: none;
        border-radius: 0 15px 15px 0;
        height: 290px;
    }
    .hero-images .parallax-image:nth-child(3){
        top: 70px;
        right: 11px;
        transform: none;
        border-radius: 0;
        width: 115px;
        height: 100px;
    }
}
