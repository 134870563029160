@import url(//fonts.googleapis.com/css?family=Roboto:300:400);

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url(assets/images/world.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
}

.App.light {
  background-image: url(assets/images/world_white.png);
}

.App * {
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.desktop-menu {
  display: none;
}

.sidebar-dark-bg {
  background-color: #1a1e2e;
  border: 1px solid #31465d;
}


.custom-btn {
  max-width: 230px;
  color: #fff;
  border-radius: 5px;
  padding: 15px;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
}

.custom-btn[disabled] {
  opacity: 0.5;
}
.custom-btn[disabled]:after {
  display: none!important;
}

/* 1 */
.btn-1 {
  background: rgb(6,14,131);
  background: linear-gradient(0deg, rgba(6,14,131,1) 0%, rgba(12,25,180,1) 100%);
  border: none;
}
.btn-1:hover {
  background: rgb(0,3,255);
  background: linear-gradient(0deg, rgba(0,3,255,1) 0%, rgba(2,126,251,1) 100%);
}
/* 9 */
.btn-9 {
  border: none;
  transition: all 0.3s ease;
  overflow: hidden;
}
.btn-9:after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1fd1f9;
  background-image: linear-gradient(315deg, #1fd1f9 0%, #b621fe 74%);
  transition: all 0.3s ease;
}
.btn-9:hover {
  background: transparent;
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
  -4px -4px 6px 0 rgba(116, 125, 136, .2),
  inset -4px -4px 6px 0 rgba(255,255,255,.5),
  inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
  color: #fff;
}
.btn-9:hover:after {
  -webkit-transform: scale(2) rotate(180deg);
  transform: scale(2) rotate(180deg);
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
  -4px -4px 6px 0 rgba(116, 125, 136, .2),
  inset -4px -4px 6px 0 rgba(255,255,255,.5),
  inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
}
/* 15 */
.btn-15 {
  background: #b621fe;
  border: none;
  z-index: 1;
}
.btn-15:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #663dff;
  border-radius: 5px;
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
  7px 7px 20px 0px rgba(0,0,0,.1),
  4px 4px 5px 0px rgba(0,0,0,.1);
  transition: all 0.3s ease;
}
.btn-15:hover {
  color: #fff;
}
.btn-15:hover:after {
  left: 0;
  width: 100%;
}
.btn-15:active {
  top: 2px;
}

.border-button {
  border: 2px solid #a237f4!important;
  margin: 0 7px!important;
}

.normal-case {
  text-transform: none!important;
}

.show-map,
.show-list {
  width: 62px;
  height: 62px;
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.show-map {
  background-image: url("../public/map-icon.png");
}

.show-list {
  background-image: url("../public/list-icon.png");
}

/*.App.dark .show-map,*/
/*.App.dark .show-list {*/
/*  filter: invert(0.8);*/
/*}*/

.flag-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid white;
  overflow: hidden;
}

.flag-icon img{
  height: 100%;
  object-fit: cover;
}

@media (min-width: 600px) {
  .desktop-menu {
    display: flex;
  }
}
.drawer-container {
  width: 60vw;
}
